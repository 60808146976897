document.querySelector('.header__dropdown').onclick = () => {
    document.querySelector('.navigation').classList.toggle('navigation--hidden');
};

document.querySelectorAll('.navigation__link').forEach(link => {
    link.onclick = () => {
        history.pushState({}, "8th Graphene & 2D Materials", link.dataset.path);
        sendAsset(link.dataset.file);
    };
});

async function sendAsset(fileName) {
    if ( !fileName.startsWith('/') ) fileName = `/${fileName}`;
    const query = await fetch(`/views${fileName}`, { method: "GET" });
    const res = await query.text();
    document.getElementById('container').innerHTML = res;
    if ( fileName == '/form' ) getAdditionalScript(`js/${fileName.substring(1)}Script.js`);
}

async function getAdditionalScript(fileName){
    if ( fileName.startsWith('/') ) fileName = fileName.substring(1);
    //fetch(fileName, { method: "GET" }).then( res => res.text()).then( data => {
        const script = document.createElement('script');
        script.src = fileName;
        script.async = true;
        document.body.appendChild(script);
    //});

}

(location.pathname === '/')?sendAsset('/home'):sendAsset(location.pathname);